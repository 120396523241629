// About webpage component content

function About() {
    const title = "Hello, I'm Mark Allen";
  
    return (
      
      <div className="home">
        <h2>{ title }</h2>
      </div>
    );
  }
  
  export default About;
  