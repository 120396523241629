// Home webpage component content

function Home() {
    const title = "welcome to mark allen's website, work in progress";
  
    return (
      
      <div className="home">
        <h2>{ title }</h2>
      </div>
    );
  }
  
  export default Home;
  